import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Card, Header, Layout, Lightbox } from '../components'
import config from '../../config/site'

const Grid = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 1030px;
  flex-wrap: wrap;

  @media only screen and (max-width: 1040px) {
    width: 100vw;
  }
`

const Content = styled.div`
  margin: -6rem auto 0 auto;
  max-width: ${props => props.theme.maxWidths.general};
  padding: 0 ${props => props.theme.contentPadding} 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BG = styled.div`
  background-color: ${props => props.theme.colors.bg};
`

const Index = ({
  data: {
    allMdx: { nodes },
  },
}) => {
  const [focusedNode, setFocusedNode] = useState(null)

  return (
    <Layout>
      <Header avatar={config.avatar} name={config.name} location={config.location} socialMedia={config.socialMedia} />
      <BG>
        <Content>
          <Grid>
            {nodes
              .sort((a, b) => a.frontmatter.order - b.frontmatter.order)
              .map((resource, index) => {
                if (!resource.frontmatter.photo) {
                  console.warn(`No photo for ${resource.fields.slug}!`)
                  return null
                }

                return (
                  <Card
                    index={index}
                    delay={index}
                    cover={resource.frontmatter.photo.childImageSharp.fluid}
                    path={resource.fields.slug}
                    key={resource.fields.slug}
                  />
                )
              })}
          </Grid>
        </Content>
      </BG>
      <Lightbox open={!!focusedNode} />
    </Layout>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query HomeQuery {
    allMdx {
      nodes {
        fields {
          slug
        }
        frontmatter {
          order
          photo {
            childImageSharp {
              fluid(maxWidth: 760, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
